import React from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router';

const Reader = () => {
   

        const navigate  = useNavigate();
    return (
        <div className='reader'>
              <div className='back' onClick={()=>navigate("/")}>
           { "<--- Back"}
        </div>
       {localStorage.getItem("verified")!==null&&localStorage.getItem("verified")!==false ? <QrReader
        constraints={{
            facingMode: 'environment'
        }}
        onResult={(result, error) => {
          if (!!result) {
            console.log(result)

           navigate("/data/"+result?.text);
            
          }
          if (!!error) {
            console.info(error);
          }
        }} 
    
      />
    :<div><a href="/">Authenticate</a></div>}
      </div>
    );
}

export default Reader;
