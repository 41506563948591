import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router';

const Details = () => {
    const [which, setWhich] = useState("");
    const [of,setOf] = useState("");
    const [email,setEmail] = useState("");
    const [uses,setUses] = useState("");
    const loader = useRef();
    const navigate = useNavigate();
    useEffect(()=>{
        const hash = window.location.href.split("/data/")[1];
        axios.post("https://us-central1-backup-55b90.cloudfunctions.net/qr/findqr",{hash}).then((res) =>{
            setWhich(res.data.which);setOf(res.data.total);
              axios.post("https://us-central1-backup-55b90.cloudfunctions.net/qr/updateuses",{email:res.data.email,uses:res.data.uses,which:res.data.which,usesArr:res.data.usesArr}); 
             setEmail(res.data.email);setUses(res.data.uses);
             loader.current.style.display="none";
});
},[])
    return (
        <>
          <div className='back' onClick={()=>navigate("/")}>
           { "<--- Back"}
        </div>
          {localStorage.getItem("verified")!==null&&localStorage.getItem("verified")!==false ?
          <div>
        <div className='loading' ref={loader}>
            <div className='loadingbckg'>
                
            </div>
        <div class="lds-ripple"><div></div><div></div></div>
        </div>
        
      
        <div className="gotten">
       <h1>{Number(which)} bilietas iš {of}</h1>
            <h2 >Bilietas panaudotas {uses!==0 && uses} {uses===1 && "kartą" } {uses===0 && "pirmą kartą"} {uses>1 && "kartus"} </h2>
            <h1 className='emailas'>{email}</h1>
            <div className='btn' onClick={() =>navigate("/reader")}>Skenuoti dar</div>
      </div>
      </div>
:<div><a href="/">Authenticate</a></div>}
      </>
    );
}

export default Details;
