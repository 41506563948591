import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

const List = () => {
    const [data, setData] = useState("");
    const navigate = useNavigate();
    const loader = useRef();
    useEffect(()=>{
        axios.post("https://us-central1-backup-55b90.cloudfunctions.net/qr/getlist").then((res)=>{
            setData(res.data)
            loader.current.style.display="none";
        })
         
    },[])
    return (
        <>
        
        <div className='back' onClick={()=>navigate("/")}>
           { "<--- Back"}
        </div>
        <div className='loading' ref={loader}>
            <div className='loadingbckg'>
                
            </div>
        <div class="lds-ripple"><div></div><div></div></div>
        </div>
        <ul className='list'>
            {data!=="" && data.map((item)=>{
                return(
                    <li className='listitem' key={Math.random()}>
                      <div> {item.email}</div>
                      <div> Pirkta bilietų:  {item.ticket_count}</div>
                        </li>
                )
            })}
        </ul>
        </>
    );
}

export default List;
