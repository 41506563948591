
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Details from './components/Details';
import Intro from './components/Intro';
import List from './components/List';
import Reader from './components/Reader';

function App() {
  return (
<Router>
  <Routes>
  <Route exact path="/" element={<Intro />} />
  <Route exact path="/reader" element={<Reader />} />
  <Route exact path="/data/:hash" element={<Details />} />
  <Route exact path="/list" element={<List />} />
  </Routes>
</Router>
  );
}

export default App;
