import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from './List';

const Intro = () => {
    const verify = () =>{
        if(value==="lapcunas"){
            localStorage.setItem("verified",true);
            window.location.reload();
        }
    }
    const [value,setValue] = useState("");
    return (
        <>
             {localStorage.getItem("verified")!==null&&localStorage.getItem("verified")!==false ? 
                  <div className='content'>
    <h1>Paulius Stanulionis | Naktinis Vilniaus Avilys</h1>
    <h3>Bilietų skenavimas</h3>
 <div className='btn'> <Link to="/reader">Naujas bilietas</Link></div> 
    </div> 
     
    :
    <div className='authentication'>
        <h1>Authenticate device</h1>
        <input type="" name="" value={value} onChange={(e)=>setValue(e.target.value)} />
        <div className='btn' onClick={()=>verify()}>
            Verify
        </div>
     
    </div>
    }
     <Link to="/list"> <div className='btnlist'>Sąrašas</div> </Link>
    </>
    );
}

export default Intro;


